#pusher {
  .pusher-menu__fon {
    background-image: url("../images/fon.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: left;
    padding-top: 0;
  }

  .pusher-menu__menu {
    background-color: #00000061;
  }
}